<template>
  <Widget
    class="post-creation"
    :enable-card-size="enableCardSize"
    :style="isWidget || isMobile?'min-height: 110px; overflow: visible;':'min-height: 130px; overflow-y: hidden'"
  >
    <div class="d-flex flex-column">
      <div class="mt-1 d-flex">
        <UserAvatar size="46" variant="light-primary" :user="loggedUser" />
        <div class="pl-1 post-creation-actions">
          <b-button
            block
            variant="outline-light"
            class="post-creation-actions__create-post text-muted"
            @click="createNewPost()"
          >
            {{ $t("posts.post-creation.create-post") }}
          </b-button>
        </div>
        <PostCreationAttachmentActions
          v-if="isWidget || isMobile"
          class="buttons-div"
          :is-widget="isWidget"
          @create-post="createNewPost"
        />
      </div>
      <PostCreationAttachmentActions
        v-if="!isWidget && !isMobile"
        class="buttons-div"
        :is-widget="isWidget"
        @create-post="createNewPost"
      />
    </div>

    <template v-if="isCreationModalVisible">
      <PostCreationFormModal
        v-model="isCreationModalVisible"
        :attachment="attachmentType"
        @posts-updated="$emit('posts-updated', $event)"
      />
    </template>
  </Widget>
</template>

<script>
// import { BButton } from 'bootstrap-vue';
import Widget from '@core/widgets/Widget.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
// import UserAvatar from '@core/components/user/UserAvatar.vue';
// import PostCreationAttachmentActions from './PostCreationAttachmentActions.vue';
// import PostCreationFormModal from './PostCreationFormModal.vue';

export default {
  name: 'PostCreation',

  components: {
    Widget,
    // BButton,
    UserAvatar: () => import('@core/components/user/UserAvatar.vue' /* webpackChunkName: "UserAvatar" */),
    PostCreationAttachmentActions: () => import('./PostCreationAttachmentActions.vue' /* webpackChunkName: "PostCreationAttachmentActions" */),
    PostCreationFormModal: () => import('./PostCreationFormModal.vue' /* webpackChunkName: "PostCreationFormModal" */),
  },
  mixins: [CardWidgetMixin],
  props: {
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreationModalVisible: false,
      attachmentType: '',
    };
  },
  computed: {
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    isMobile() {
      return window.innerWidth < 700;
    },
  },
  methods: {
    createNewPost(attachmentType) {
      this.attachmentType = attachmentType || '';
      this.isCreationModalVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes
.buttons-div {
  margin-top: 6px;
}
.post-creation {
  max-width: 910px;
}
.post-creation-actions {
  flex-grow: 1;
  &__create-post {
    background-color: $white;
    padding: $spacer $spacer * 2;
    border-radius: 50px;
    border-color: $text-muted !important;
    text-align: left;
  }
}
</style>
