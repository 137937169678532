<template>
  <VerticalContainer
    v-if="!userKey"
    id="posts"
    :key="postsListKey"
    :item-name="$t('posts.title')"
    :request-config="requestConfig"
    :no-loading="noLoading"
    :per-page="10"
    class="posts-list"
  >
    <template v-if="canList || isStaff" #default="{ item }">
      <PostRow
        :post="item"
        :is-widget="isWidget"
        class="mb-1 mb-lg-2 mb-xl-3"
        :enable-card-size="enableCardSize"
        @edit-post="handleEditPost"
      />
    </template>
    <template #footer>
      <template v-if="isEditingPost">
        <PostCreationFormModal
          v-model="isEditingPost"
          :post="postToEdit"
          @posts-updated="postsListKey += 1"
        />
      </template>
    </template>
  </VerticalContainer>
  <PostsUserList
    v-else
    :user-key="userKey"
    :per-page="10"
    :enable-card-size="enableCardSize"
  />
</template>

<script>
// import VerticalContainer from '@core/components/containers/VerticalContainer.vue';
import CardWidgetMixin from '@core/mixins/widgets/CardWidgetMixin';
import OrderBy from '@core/constants/OrderBy';
import { checkPermissions } from '@/@core/utils/roles-utils';
// import PostRow from './PostRow.vue';
// import PostCreationFormModal from './post-creation/PostCreationFormModal.vue';
// import PostsUserList from './PostsUserList.vue';

export default {
  name: 'PostsList',
  components: {
    VerticalContainer: () => import('@core/components/containers/VerticalContainer.vue' /* webpackChunkName: "VerticalContainer" */),
    PostRow: () => import('./PostRow.vue' /* webpackChunkName: "PostRow" */),
    PostsUserList: () => import('./PostsUserList.vue' /* webpackChunkName: "PostsUserList" */),
    PostCreationFormModal: () => import('./post-creation/PostCreationFormModal.vue' /* webpackChunkName: "PostCreationFormModal" */),
  },
  mixins: [CardWidgetMixin],
  props: {
    userKey: {
      type: String,
      default: null,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postsListKey: 0,
      postToEdit: null,
      isEditingPost: false,
      noLoading: false,
    };
  },
  computed: {
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.collective.isStaff;
    },
    canList() {
      return checkPermissions(
        'view',
        'post',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    requestConfig() {
      return {
        orderByCreationTime: OrderBy.DESC,
        ...(this.userKey ? { userKey: this.userKey } : {}),
      };
    },
  },
  sockets: {
    forceUpdateData(data) {
      if (data.itemType === 'posts') {
        this.noLoading = true;
        this.postsListKey += 1;
      }
    },
  },
  methods: {
    handleEditPost(post) {
      this.postToEdit = post;
      this.isEditingPost = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.posts-list {
  max-width: 910px;
  min-height: 260px;
}
</style>
