<template>
  <b-container tag="section" class="post-list">
    <post-creation class="m-auto" @posts-updated="handlePostsUpdated" />
    <posts-list-component :key="listKey" class="mt-2 mx-auto" :user-key="userKey" />
  </b-container>
</template>

<script>
import { BContainer } from 'bootstrap-vue';
import PostCreation from '@/views/apps/posts/components/post-creation/PostCreation.vue';
import PostsListComponent from '@/views/apps/posts/components/PostsList.vue';
import { AppIDLUT } from '@copernicsw/community-common';
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'PostsList',
  components: { PostCreation, PostsListComponent, BContainer },
  props: {
    userKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      listKey: 0,
    };
  },
  computed: {
    appId() {
      return 13;
    },
    app() {
      return this.$store.getters.apps.apps[this.appId];
    },
    appTitle() {
      return translateTranslationTable(this.$store.state.locale.currentLocale, this.app?.customizationName);
    },
  },
  created() {
    this.updateBreadcrumbs();
  },
  methods: {
    handlePostsUpdated() {
      // FIXME We use this to force the reloading of the list.
      // Remove this once the posts store has been properly implemented.
      this.listKey += 1;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || this.$t('posts.title'),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>
<style lang="scss" scoped>
.post-list {
  padding: 0!important;
}
</style>
